.disabled {
  pointer-events: none;
}

.constraints {
  margin: 2px 0 15px 0;
  color: var(--grey);
  line-height: 12px;

  * {
    font-size: 12px;
    line-height: 12px;
  }
}

.uploadBtnWrapper {
  position: relative;
  margin-right: 10px;
  cursor: pointer;

  input {
    width: 76px;
    opacity: 0;
    cursor: pointer;

    // upload button is covered with the file button so we need to pass the hover state
    &:hover + button {
      background-color: var(--secondaryGreen);
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  button {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}
