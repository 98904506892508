div.copyLinkPopupContainer {
  margin: 0 0 0 2px;
  overflow: visible;

  .wrapper {
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
    background: white;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .25);
    width: fit-content;
    margin: auto;

    &:before {
      content: "";
      position: absolute;
      right: calc(50% - 5px);
      top: 100%;
      border: 5px solid transparent;
      border-width: 8px 5px 0 5px;
      border-top-color: white;
    }
  }
}

.deletePopupContainer {
  padding: 30px 30px 15px 30px;

  .close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .buttons {
    margin: 20px 20px 5px 20px;
    display: flex;
    justify-content: space-evenly;

    button {
      min-width: 100px;
    }
  }
}

.errorPopover {
  position: relative;
  transform: translateY(-100%);
  margin-top: -12px;
  white-space: nowrap;
  font-size: 14px;
  min-width: max-content;
  text-align: center;
  background-color: var(--pink);

  span {
    display: block;
    padding: 4px 8px;
    color: white;
  }
}

.popoverArrow {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--pink);
}
