.tagContainer {
  padding: 2px 8px 8px 8px;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: min-content;
}

.taggingBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding: 4px 4px;
  margin: 4px;
  background-color: var(--beigeLight);
  border-radius: 16px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, .07);
  height: 22px;
  transition: box-shadow .1s ease;

  &.greenBox {
    background-color: var(--primaryGreen);

  }

  &.whiteBox {
    background-color: var(--white);

  }

  &:hover {
    box-shadow: 0px 3px 12px rgba(0, 0, 0, .20);

  }
}

.tagText {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: var(--primaryGreen);
  padding-right: 2px;

  &.whiteText {
    color: var(--white);
  }
}

.dropBox {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  width: 190px;
  min-height: 70px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 16px;
  background: #F2F4F5;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, .07);
  padding: 7px;
}


.dropBox::-webkit-scrollbar {
  width: 10px;
}

.dropBox::-webkit-scrollbar-track {
  background: #F2F4F5;
  border-radius: 16px;
}

.dropBox::-webkit-scrollbar-thumb {
  background: #F2F4F5;
  border: 1px solid #C1C1C1;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px #F2F4F5;
}
