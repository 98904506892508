@import "../../style/media-query.scss";

div.fullModal {
  max-width: unset;
  max-height: unset;
}

div.drawer {
  max-height: 88%;
  max-width: unset;
  overflow: hidden;
  margin-top: auto;
  border-radius: 20px 20px 0 0;
}

.absoluteClose {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 0;
  z-index: 22;
  height: calc(var(--vh) * 100);

  &:not(.mobile) {
    transform: translateY(-50%) translateX(50vw);
  }

  &.mobile {
    top: 2px;
    right: 0;

    .close {
      color: black;
      top: 4px;
      right: 4px;
      font-size: 24px;
    }
  }

  .close {
    position: absolute;
    top: 34px;
    right: 26px;
    z-index: 2;
    color: white;

    &.mobile {
      top: 10px;
      right: 10px;
    }
  }
}

.title {
  position: absolute;
  top: -15px;
  left: 50%;
  font-weight: bold;
  font-size: 20px;
  transform: translateX(-50%) translateY(-100%);
  z-index: 2;
  color: white;
  width: 100%;
  text-align: center;
}

div.shareModalContainer {
  background: black;
  overflow: hidden;
  max-width: unquote("min(calc(100vw - 140px), 1100px)");
  max-height: unquote("max(calc(45vh), 458.94px)");

  >div {
    position: relative;
  }

  .close {
    position: absolute;
    top: 17px;
    right: 17px;
    z-index: 2;
  }

  .commentSection {
    height: 100%;
    max-height: 400px;
    width: 475px;
  }

  .mediaTitle {
    max-width: 100%;
    min-height: 32px;
    padding: 20px 15px;
    flex-wrap: wrap;
    word-break: break-word;
    min-block-size: fit-content;
  }

  .shareMedia {
    width: 100%;
    position: relative;

    video,
    img {
      display: flex;
      image-rendering: -webkit-optimize-contrast;
      margin: auto 0;
    }
  }

  .heartContainer {
    cursor: pointer;
    font-size: 12px;

    &.liked svg {
      color: #DC3D7D;
      fill: #DC3D7D;
    }
  }


  .mobileHeader {
    flex-direction: column;
    padding: 20px 15px;
    height: fit-content;

    .mediaTitle {
      padding: 16px 0 0 0;
      max-width: 100%;
    }
  }

  &.mobile {
    max-height: calc(var(--vh) * 100);
    height: calc(var(--vh) * 100);
    width: 100vw;
    max-width: 100vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;

    &.drawer {
      max-height: unset;
      height: 100%;
      position: relative;
      padding-top: 24px;

      .commentSection {
        width: 100%;
      }
    }

    .rightSection {
      flex-grow: 1;
      align-items: center;
      max-width: unset;
      padding-top: 10px;
      padding-left: 0;
    }

    .taggingWrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    .commentSection {
      min-height: 0;
      max-height: 500px;
      min-width: 0;
    }

    >div:not(.mobileHeader):not([class*="editTitleContainer"]) {
      flex-direction: column;
      height: 100%;
    }

    .shareMedia {
      margin: 0;
      min-height: fit-content;

      img,
      video {
        width: 100vw;
      }
    }
  }
}

.next,
.prev {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  border: 0;
  background: white;
  opacity: .5;
  color: var(--grey);
  position: absolute;
  top: 50%;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: .8;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &.mobile {
    background: var(--charcoal);
    color: white;
    opacity: .7;

    &:active {
      opacity: .9;
    }
  }
}

.prev {
  right: initial;
  left: 16px;
  transform: translate3d(-150%, -50%, 0);

  &.mobile {
    top: 4px;
    left: 4px;
    transform: translate3d(0, calc(25px + 16vw), 0);
  }
}

.next {
  transform: translate3d(150%, -50%, 0);

  &.mobile {
    top: 4px;
    right: 4px;
    transform: translate3d(0, calc(25px + 16vw), 0);
  }


}

.viewerCount {
  top: 4px !important;
  right: 4px !important;
  left: auto !important;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
}

.editBtn {
  top: -15px;
  right: 5px;
}

.editTitleContainer {
  position: relative;
  max-width: 100%;

  .plainTitle {
    width: 100%;
    display: flex;
  }
}

div.editContentContainer {
  overflow: visible;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .25);

  .textarea {
    position: relative;

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      border-color: var(--mediumGrey);
      border-width: 0 0 1px 0;
      font-size: 12px;
      line-height: 18px;
    }

    .letterCount {
      position: absolute;
      bottom: -20px;
      right: 4px;
      font-size: 10px;
    }
  }

  button {
    width: fit-content;
  }
}
