.editTitleContainer {

  .user {
    display: flex;
    flex-direction: row;
    padding: 10px;

    .userName {
      font-size: 18px;
      flex-direction: column;
      display: flex;
      margin-left: 15px;

      .time {
        font-size: 12px;
      }
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    position: relative;

    .title {
      font-size: 16px;
      padding: 0 15px;
      color: var(--charcoal);
    }

    .editButton {
      font-size: inherit;
      font-weight: 500;
      display: inline-flex;
      padding: 5px 10px;
      width: fit-content;
      color: var(--secondaryGreen);
      margin-left: auto;
      background: none;
      border: 0;
      position: absolute;
      top: -40px;
      right: 0;
    }

    .textarea {
      position: relative;

      textarea {
        width: calc(100% - 20px);
        margin: auto;
        border-radius: 7px;
        padding: 0 5px;
        border: 1px solid #b8b8b8;
        font-size: 16px;
      }

      .letterCount {
        color: unset;
        position: absolute;
        top: -20px;
        right: 15px;
        font-size: 12px;
      }
    }
  }
}
