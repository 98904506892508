.cameraButtonContainer {
  position: relative;
}

div.cameraDrop {
  background: #2E2D2D;
  border-radius: 15px;
  color: white;
  min-width: fit-content;
  max-width: 275px;

  h3 {
    margin: 0 0 10px 0;
    white-space: nowrap;
    font-size: 16px;
  }

  // This is part of the popup, used to cover the area in the bottom so the mouse leave event is not triggered
  .extension {
    content: "";
    display: block;
    height: 35px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

.cameraButton > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchCameraIcon {
  width: 20px;
  height: auto;
}

div.radioContainer {
  align-self: flex-start;

  label {
    font-size: 10px;
    line-height: 16px;
    color: white;

    > div > div {
      border-color: white !important;
      width: 16px;
      height: 16px;

      svg {
        fill: white;
      }
    }
  }
}
