.roundButton {
  padding: 0;
  border: 0;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;

  .btnWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    svg:not(.loader),
    .loaderWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: auto;
      display: flex;
    }
  }

  &:hover {
    opacity: .75;
  }

  &:active {
    opacity: 1;
  }
}
