@import "../../../style/media-query.scss";

.header {
  position: relative;
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  display: flex;
  align-items: center;
  color: #262627;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;
  padding: 30px 48px;
  min-height: fit-content;

  @media screen and (max-width: #{$tablet-portrait-width}) {
    padding: 20px 28px;
    font-size: 28px;
  }

  .close {
    position: absolute;
    top: 35px;
    right: 20px;

    @media screen and (max-width: #{$tablet-portrait-width}) {
      font-size: 22px;
      top: 22px;
      right: 8px;
      padding: 20px 28px;
    }
  }
}
